import { Switch as AntSwitch } from "antd";
import { StyledButton, StyledSwitch } from "./styles";
import { ButtonProps, SwitchProps } from "./types";

export const Button = ({
  color,
  fixedWidth,
  name,
  type,
  value,
  onClick,
  children,
}: ButtonProps & {type?: string, value?: string}) => (
  <StyledButton color={color} fixedWidth={fixedWidth} name={name} onClick={onClick} type={type} value={value}>
    {children}
  </StyledButton>
);

export const Switch = ({
  checked,
  onClick,
  children,
}: SwitchProps) => (
  <>
    <label>{children}</label>
    <AntSwitch checked={checked} onClick={onClick} />
  </>
);
