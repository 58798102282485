import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { app } from "../../firebase";
import { useState } from "react";
import { Row, Col, message } from "antd";
import { withTranslation } from "react-i18next";
import { Slide, Zoom } from "react-awesome-reveal";
import { ContactProps, ValidationTypeProps } from "./types";
import { useForm } from "../../common/utils/useForm";
import validate from "../../common/utils/validationRules";
import { Button } from "../../common/Button";
import Block from "../Block";
import Input from "../../common/Input";
import { FormContainer, FormGroup, Span, ButtonContainer, SwitchText } from "./styles";

const LoginSignup = ({ title, content, id, t }: ContactProps) => {
  const [isLogin, setIsLogin] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = () => {
    const auth = getAuth(app);
    signInWithEmailAndPassword(auth, values.email, values.password)
      .then((userCredential) => {
        message.success("Successfully logged in");
      })
      .catch((error) => {
        message.error(error.message);
      });
  }

  const handleSignup = () => {
    const auth = getAuth(app);
    createUserWithEmailAndPassword(auth, values.email, values.password)
      .then((userCredential) => {
        message.success("Successfully signed up");
      })
      .catch((error) => {
        message.error(error.message);
      });
  }

  const onSubmit = (values: any, showMessage: Function) => {
    if (isLogin) {
      handleLogin();
    } else {
      handleSignup();
    }
  }

  const { values, errors, handleChange, handleSubmit } = useForm(validate, onSubmit) as any;

  const ValidationType = ({ type }: ValidationTypeProps) => {
    const ErrorMessage = errors[type];
    return (
      <Zoom direction="left">
        <Span errors={errors[type]}>{ErrorMessage}</Span>
      </Zoom>
    );
  };

  const switchForm = () => {
    setIsLogin(!isLogin);
  }

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  }

  return (
    <FormContainer id={id}>
      <Row justify="space-between" align="top">
       <Col lg={24} md={24} sm={24} xs={24} style={{ position: "relative" }}>
          <Slide direction="left">
            <Block title={isLogin ? "Login" : "Signup"} content="" />
          </Slide>
        </Col>
         <Col lg={24} md={24} sm={24} xs={24}>
          <Slide direction="right">
            <FormGroup autoComplete="off" onSubmit={handleSubmit}>
              <Col span={24}>
                <Input
                  type="email"
                  name="email"
                  placeholder="Your Email"
                  value={values.email || ""}
                  onChange={handleChange}
                />
                <ValidationType type="email" />
              </Col>
              <Col span={24}>
              <Input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="Your Password"
                  value={values.password || ""}
                  onChange={handleChange}
                />
                <label>
                  <input
                    type="checkbox"
                    checked={showPassword}
                    onChange={handlePasswordVisibility}
                  />
                  Show Password
                </label>
                <ValidationType type="password" />
              </Col>
              <ButtonContainer><Button name="submit" value={isLogin ? t("Login") : t("Signup")}>{isLogin ? t("Login") : t("Signup")}</Button></ButtonContainer>
            </FormGroup>
          </Slide>
        </Col>
      </Row>
      <SwitchText onClick={switchForm}>{isLogin ? "Create a new account" : "Login if you already have an account"}</SwitchText>
    </FormContainer>
  );
};

export default withTranslation()(LoginSignup);
