import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Row, Col, Drawer, Modal } from "antd";
import { withTranslation } from "react-i18next";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import { Button } from "../../common/Button";
import SigninSignupForm from '../SigninSignupForm';

import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
} from "./styles";

const Header = ({ t }: any) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const history = useHistory();

  const showLoginModal = () => {
      setIsModalVisible(true);
  };

  const handleLogin = () => {
    setIsModalVisible(false);
  };
  
  const CloseModal = () => {
    setIsModalVisible(false);
  };

  const [visible, setVisibility] = useState(false);

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {
    const scrollTo = (id: string) => {
      history.push('/');
      setTimeout(() => {
        const element = document.getElementById(id) as HTMLDivElement;
        element.scrollIntoView({
          behavior: "smooth",
        });
        setVisibility(false);
      }, 0);
    };
    return (
      <>
        <CustomNavLinkSmall onClick={() => scrollTo("about")}>
          <Span>{t("About")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("mission")}>
          <Span>{t("Mission")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("product")}>
          <Span>{t("Product")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall>
          <Span>
            <Link to="/GTMContainerViewer">{t("GTM Container Viewer")}</Link>
          </Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall style={{ width: "180px" }} onClick={showLoginModal}>
          <Span>
            <Button>{t("Login")}</Button>
          </Span>
        </CustomNavLinkSmall>
      </>
    );
  };

  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
            <SvgIcon src="logo.svg" width="101px" height="64px" />
          </LogoContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={showDrawer}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} visible={visible} onClose={onClose}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={onClose}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </Drawer>
        <Modal title="" visible={isModalVisible} onOk={handleLogin} onCancel={CloseModal} footer={null}>
          <SigninSignupForm title="" id="" content="" />
        </Modal>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
