import { validateProps } from "../../common/types";

export default function validate(values: validateProps) {
  let errors: validateProps = {};

  // Email validation
  if (!values.email) {
    errors.email = "Email is required";
  } else if (
    !/^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})+$/.test(
      values.email
    )
  ) {
    errors.email = "Email address is invalid";
  }

  // Only validate name if it is present
  if (values.name) {
    if (!values.name) {
      errors.name = "Name is required";
    } else if (!/^([a-zA-Z ]){2,30}$/.test(values.name)) {
      errors.name = "Name is invalid";
    }
  }

  // Password validation
  if (!values.password) {
    errors.password = "Password is required";
  } else if (values.password.length < 6) {
    errors.password = "Password needs to be 6 characters or more";
  }

  // Only validate message if it is present
  if (values.message) {
    if (!values.message) {
      errors.message = "Message is required";
    } else if (values.message.length < 10) {
      errors.message = "Message needs to be 10 characters or more";
    }
  }

  return errors;
}