const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/GTMContainerViewer"],
    exact: true,
    component: "GTMContainerViewer",
  },
];

export default routes;
