import styled from "styled-components";

export const StyledButton = styled("button")<any>`
  background: ${(p) => p.color || "#2e186a"};
  color: ${(p) => (p.color ? "#2E186A" : "#fff")};
  font-size: 1rem;
  font-weight: 700;
  width: 100%;
  border: 1px solid #edf3f5;
  border-radius: 4px;
  padding: 13px 0;
  cursor: pointer;
  margin-top: 0.625rem;
  max-width: 180px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 16px 30px rgb(23 31 114 / 20%);

  &:hover,
  &:active,
  &:focus {
    color: #fff;
    border: 1px solid rgb(255, 130, 92);
    background-color: rgb(255, 130, 92);
  }
`;

export const SwitchText = styled("span")<any>`
  color: #2e186a;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background-color: #4caf50;
    color: white;
  }
`;


export const FormGroup = styled("form")`
position: relative;
width: 100%;
padding: 20px;
display: flex;
flex-direction: column;
justify-content: center;
`;

export const Span = styled.span<{ errors?: string }>`
  display: block;
  color: ${(props) => (props.errors ? "red" : "black")};
`;

export const ButtonContainer = styled("div")<any>`
 margin-top: 1rem;
 display: flex;
 justify-content: center;
 align-items: center;
`;

export const FormContainer = styled("div")<any>`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 20px;
`;
