import { useState, useEffect } from "react";
import { notification } from "antd";

export const useForm = (validate: any, callback: Function = () => {}) => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    setErrors(validate(values));
    setIsSubmitting(true);
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback(values, openNotificationWithIcon);
    }
  }, [errors, isSubmitting]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
    setErrors((errors) => ({ ...errors, [event.target.name]: "" }));
  };

  type NotificationType = "success" | "error";

  const openNotificationWithIcon = (type: NotificationType, message: string) => {
    notification[type]({
      message: "Notification",
      description: message,
    });
  };

  return {
    handleChange,
    handleSubmit,
    values,
    errors,
  };
};
